import { RESET_REDUCER_STATE } from 'src/actions';
import * as ACTIONS from 'src/actions/alertActions';
import { sortByDateProperty } from 'src/utils/arrayFunctions';

const initialState = {
    alertsById: {},
    newsById: {},
    alertsLoading: true,
    newsLoading: true,
    unreadAlerts: [],
    unreadNews: [],
    alerts: [],
    news: [],
    shouldRefreshAlerts: false,
    shouldRefreshNews: false,
    alertsPage: 1,
    newsPage: 1,
    pageSize: 20
};

const alertsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.ALERTS_LOADING: {
            return {
                ...state,
                alertsLoading: Boolean(action.data)
            };
        }
        case ACTIONS.NEWS_LOADING: {
            return {
                ...state,
                newsLoading: Boolean(action.data)
            };
        }
        case ACTIONS.ALERTS_LOADED: {
            if (!Array.isArray(action?.data?.items)) {
                return state;
            }

            const alertsById = action.data.items.reduce((items, item) => ({ ...items, [item?.id]: item }), state.alertsById);
            const alerts = Object.keys(alertsById).map((id) => alertsById[id]).sort(sortByDateProperty('published', 'asc'));

            return {
                ...state,
                alertsById,
                alertsLoading: false,
                alerts,
                alertCount: alerts.length,
                alertTotalCount: action?.data?.count ?? 0,
                unreadAlerts: alerts.filter((item) => item?.read === false) ?? [],
                shouldRefreshAlerts: false
            };
        }
        case ACTIONS.NEWS_LOADED: {
            if (!Array.isArray(action?.data?.items)) {
                return state;
            }

            const newsById = action.data.items.reduce((items, item) => ({ ...items, [item?.id]: item }), state.newsById);
            const news = Object.keys(newsById).map((id) => newsById[id]).sort(sortByDateProperty('published', 'asc'));

            return {
                ...state,
                newsById,
                newsLoading: false,
                news,
                newsCount: news.length,
                newsTotalCount: action?.data?.count ?? 0,
                unreadNews: news.filter((item) => item?.read === false) ?? [],
                shouldRefreshNews: false
            };
        }
        case ACTIONS.REFRESH_ALERTS: {
            return {
                ...state,
                alertsLoading: true,
                shouldRefreshAlerts: !state.shouldRefreshAlerts
            };
        }
        case ACTIONS.REFRESH_NEWS: {
            return {
                ...state,
                newsLoading: true,
                shouldRefreshNews: !state.shouldRefreshNews
            };
        }
        case ACTIONS.LOAD_MORE_ALERTS: {
            return {
                ...state,
                alertsLoading: true,
                alertsPage: state.alertsPage + 1,
                shouldRefreshAlerts: true
            };
        }
        case ACTIONS.LOAD_MORE_NEWS: {
            return {
                ...state,
                newsLoading: true,
                newsPage: state.newsPage + 1,
                shouldRefreshNews: true
            };
        }
        case RESET_REDUCER_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default alertsReducer;
