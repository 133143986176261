import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ALERTS_LOADED, ALERTS_LOADING, NEWS_LOADED, NEWS_LOADING } from 'src/actions/alertActions';
import { signalREventSubscribe, signalREventUnsubscribe } from 'src/actions/signalrActions';
import { useHttpGetRequest } from 'src/utils/httpClient';

const NewsAndAlertsProvider = () => {
    const { selectedCustomerId } = useSelector((state) => state.auth);
    const { shouldRefreshAlerts, shouldRefreshNews, pageSize, alertsPage, newsPage } = useSelector((state) => state.newsAndAlerts);
    const dispatch = useDispatch();

    const { payload: newsPayload, query: refreshNews } = useHttpGetRequest({
        method: 'GET',
        endpoint: '/newsandalerts/usermessages',
        params: {
            pageSize,
            page: newsPage,
            filters: 'Type == News'
        }
    }, false);

    React.useEffect(() => {
        if (newsPayload) {
            dispatch({ type: NEWS_LOADED, data: newsPayload });
        }
    }, [dispatch, newsPayload]);

    React.useEffect(() => {
        if (shouldRefreshNews) {
            refreshNews();
        }
    }, [shouldRefreshNews, refreshNews]);

    const { payload: alertsPayload, query: refreshAlerts } = useHttpGetRequest({
        method: 'GET',
        endpoint: '/newsandalerts/usermessages',
        params: {
            pageSize,
            page: alertsPage,
            filters: 'Type == Alert'
        }
    }, false);

    React.useEffect(() => {
        if (alertsPayload) {
            dispatch({ type: ALERTS_LOADED, data: alertsPayload });
        }
    }, [dispatch, alertsPayload]);

    React.useEffect(() => {
        if (shouldRefreshAlerts) {
            refreshAlerts();
        }
    }, [shouldRefreshAlerts, refreshAlerts]);

    React.useEffect(() => {
        dispatch({ type: ALERTS_LOADING, data: true });
        dispatch({ type: NEWS_LOADING, data: true });
    }, [dispatch]);

    // Subscribe to IInfoMessageEvent event
    React.useEffect(() => {
        if (selectedCustomerId) {
            refreshAlerts();
            refreshNews();

            dispatch(signalREventSubscribe('IInfoMessageEvent', () => {
                refreshAlerts();
                refreshNews();
            }));
        }

        return () => {
            dispatch(signalREventUnsubscribe('IInfoMessageEvent'));
        };
    }, [dispatch, refreshAlerts, refreshNews, selectedCustomerId]);

    return null;
};

export default NewsAndAlertsProvider;