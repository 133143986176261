export const SET_SENDER = 'SET_SENDER';

export const SET_MESSAGECONTENT = 'SET_MESSAGECONTENT';
export const SET_MEDIAATTACHMENT = 'SET_MEDIAATTACHMENT';
export const SET_WHATSAPPATTACHMENT = 'SET_WHATSAPPATTACHMENT';
export const SET_EMAILATTACHMENT = 'SET_EMAILATTACHMENT';

export const SET_RECIPIENTS = 'SET_RECIPIENTS';
export const SET_CSVRECIPIENTS = 'SET_CSVRECIPIENTS';
export const RESET_CSVRECIPIENTS = 'RESET_CSVRECIPIENTS';
export const SET_GROUPCONTACTSFILTER = 'SET_GROUPCONTACTSFILTER';
export const SET_GROUPCONTACTCOUNT = 'SET_GROUPCONTACTCOUNT';

export const SET_OTHERSETTINGS = 'SET_OTHERSETTINGS';
export const TOGGLE_STAGGEREDSEND = 'TOGGLE_STAGGEREDSEND';
export const TOGGLE_BATCHEDSEND = 'TOGGLE_BATCHEDSEND';

export const SET_CLEARSETTINGSONSEND = 'SET_CLEARSETTINGSONSEND';
export const CLEARSENDMESSAGESETTINGS = 'CLEARSENDMESSAGESETTINGS';

export const VALIDATE_STEPS = 'VALIDATE_STEPS';

export const setSender = (data) => (dispatch) => dispatch({
    type: SET_SENDER,
    data
});

export const setMessageContent = (data) => (dispatch) => dispatch({
    type: SET_MESSAGECONTENT,
    data
});

export const setMediaAttachment = (data) => (dispatch) => dispatch({
    type: SET_MEDIAATTACHMENT,
    data
});
export const setWhatsAppAttachment = (data) => (dispatch) => dispatch({
    type: SET_WHATSAPPATTACHMENT,
    data
});

export const setEmailAttachment = (data) => (dispatch) => dispatch({
    type: SET_EMAILATTACHMENT,
    data
});

export const setRecipients = (data) => (dispatch) => dispatch({
    type: SET_RECIPIENTS,
    data
});

export const setCsvRecipients = (data) => (dispatch) => dispatch({
    type: SET_CSVRECIPIENTS,
    data
});

export const resetCsvRecipients = (data) => (dispatch) => dispatch({
    type: RESET_CSVRECIPIENTS,
    data
});

export const setGroupContactsFilter = (data) => (dispatch) => dispatch({
    type: SET_GROUPCONTACTSFILTER,
    data
});

export const setGroupContactCount = (data) => (dispatch) => dispatch({
    type: SET_GROUPCONTACTCOUNT,
    data
});

export const setOtherSettings = (data) => (dispatch) => dispatch({
    type: SET_OTHERSETTINGS,
    data
});

export const toggleStaggeredSend = (data) => (dispatch) => dispatch({
    type: TOGGLE_STAGGEREDSEND,
    data
});

export const toggleBatchedSend = (data) => (dispatch) => dispatch({
    type: TOGGLE_BATCHEDSEND,
    data
});

export const validateSteps = () => (dispatch, getState) => {
    dispatch({
        type: VALIDATE_STEPS
    });

    return getState()?.sendMessage?.allStepsComplete ?? false;
};

export const setClearSettingsOnSend = (data) => (dispatch) => dispatch({
    type: SET_CLEARSETTINGSONSEND,
    data
});

export const clearSendMessageSettings = () => (dispatch) => dispatch({
    type: CLEARSENDMESSAGESETTINGS
});