/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect, useParams, generatePath } from 'react-router-dom';
import MainLayout from 'src/layouts/Main';
import PropTypes from 'prop-types';
import * as products from 'src/smartDialogProducts';
import OneWayServiceTitle from 'src/components/SmartDialogBreadCrumbs/OneWayServiceTitle';
import TwoWayServiceTitle from 'src/components/SmartDialogBreadCrumbs/TwoWayServiceTitle';
import GroupMessagingServiceTitle from 'src/components/SmartDialogBreadCrumbs/GroupMessagingServiceTitle';
import CodeServiceTitle from 'src/components/SmartDialogBreadCrumbs/CodeServiceTitle';
import CarrierBillingServiceTitle from 'src/components/SmartDialogBreadCrumbs/CarrierBillingServiceTitle';
import SurveyServiceTitle from 'src/components/SmartDialogBreadCrumbs/SurveyServiceTitle';
import MessagingAccountTitle from 'src/components/SmartDialogBreadCrumbs/MessagingAccountTitle';
import AuthenticationStatusPage from 'src/auth/AuthenticationStatusPage';
import ErrorLayout from 'src/layouts/Error';
import DashboardDefaultView from 'src/views/DashboardDefault';
import DialogServiceTitle from 'src/components/SmartDialogBreadCrumbs/DialogServiceTitle';
import AdBanServiceTitle from 'src/components/SmartDialogBreadCrumbs/AdBanServiceTitle';
import UserTitle from 'src/components/SmartDialogBreadCrumbs/UserTitle';
import AccessGroupTitle from 'src/components/SmartDialogBreadCrumbs/AccessGroupTitle';
import { ErrorBoundaryTest } from 'src/components/ErrorBoundary';
import { useSelector } from 'react-redux';

export const defaultRoute = '/dashboard';

const RedirectToDefaultCustomer = ({ path }) => {
    const { isSysAdmin, customerRights, customerRightsLoading } = useSelector((state) => state.auth);
    const params = useParams();

    if (customerRightsLoading && isSysAdmin) {
        return <AuthenticationStatusPage>Fetching all customers</AuthenticationStatusPage>;
    }

    if (customerRightsLoading) {
        return <AuthenticationStatusPage>Loading</AuthenticationStatusPage>;
    }
    /* No customer rights redirect to custom page which shows no customer rights information */
    if (customerRights.length === 0) {
        return <Redirect to="/errors/error-403" />;
    }

    if (params && params.customerId && params.customerId.toLowerCase && params.customerId.toLowerCase() === 'default') {
        const redirectUrl = generatePath(path ?? '/:customerId', {
            ...params,
            customerId: customerRights[0].customerId
        });

        return <Redirect to={redirectUrl} />;
    }

    return <Redirect to={`/${customerRights[0].customerId}`} />;
};

RedirectToDefaultCustomer.propTypes = {
    path: PropTypes.string
};

const RedirectToCustomerDefaultPage = () => {
    const { customerId } = useParams();
    return <Redirect to={`/${customerId}/dashboard`} />;
};

const RedirectWithParams = ({ path }) => {
    const params = useParams();
    const redirectPath = generatePath(path, params);
    return <Redirect to={redirectPath} />;
};

RedirectWithParams.propTypes = {
    path: PropTypes.string
};

const WithValidCustomerId = ({ route, children }) => {
    const { customerId } = useParams();
    const { isSysAdmin, customerRights, customerRightsLoading } = useSelector((state) => state.auth);

    if (customerRights.find((customer) => customer.customerId === customerId)) {
        return children;
    }

    if (customerRightsLoading && isSysAdmin) {
        return <AuthenticationStatusPage>Fetching all customers</AuthenticationStatusPage>;
    }

    if (customerRightsLoading) {
        return <AuthenticationStatusPage>Loading</AuthenticationStatusPage>;
    }

    return <RedirectToDefaultCustomer path={route.path} />;
};

WithValidCustomerId.propTypes = {
    route: PropTypes.object,
    children: PropTypes.object
};

export default [
    {
        path: '/errors',
        component: ErrorLayout,
        routes: [
            {
                path: '/errors/error-401',
                exact: true,
                component: lazy(() => import('src/views/Error401'))
            },
            {
                path: '/errors/error-403',
                exact: true,
                component: lazy(() => import('src/views/Error403'))
            },
            {
                path: '/errors/error-404',
                exact: true,
                component: lazy(() => import('src/views/Error404'))
            },
            {
                path: '/errors/error-500',
                exact: true,
                component: lazy(() => import('src/views/Error500'))
            },
            {
                component: () => <Redirect to="/errors/error-404" />
            }
        ]
    },
    {
        path: '/',
        exact: true,
        component: RedirectToDefaultCustomer
    },
    {
        path: '/:customerId/*',
        component: (props) => (
            <WithValidCustomerId {...props}>
                <MainLayout {...props} />
            </WithValidCustomerId>
        ),
        routes:
            [
                {
                    path: '/:customerId/dashboard',
                    exact: true,
                    component: DashboardDefaultView
                },
                {
                    path: '/:customerId/dialogs',
                    // Disable breadcrumbs on Dialogs page by not setting title
                    /* title: 'Dialogs', */
                    exact: false,
                    component: lazy(() => import('src/views/Dialogs')),
                    restrictedToProductTypes: [products.DIALOGSERVICE],
                    routes: [
                        {
                            path: '/:customerId/dialogs',
                            exact: true,
                            component: () => <RedirectWithParams path="/:customerId/dialogs" />
                        },
                        {
                            path: '/:customerId/dialogs',
                            title: 'Dialog Inbox',
                            linkable: true,
                            exact: false,
                            component: lazy(() => import('src/views/Dialogs/DialogInbox')),
                            restrictedToProductTypes: [products.DIALOGSERVICE],
                        }
                    ]
                },
                {
                    path: '/:customerId/sendmessages',
                    title: 'Send Messages',
                    linkable: false,
                    exact: false,
                    component: lazy(() => import('src/views/SendMessage')),
                    restrictedToProductTypes: [products.DIALOGSERVICE],
                    routes: [
                        {
                            path: '/:customerId/sendmessages',
                            title: 'Send Message',
                            linkable: true,
                            exact: false,
                            component: lazy(() => import('src/views/SendMessage/SendMessage')),
                            restrictedToProductTypes: [products.DIALOGSERVICE]
                        }
                    ]
                },
                {
                    path: '/:customerId/services',
                    title: 'Services',
                    exact: true,
                    component: RedirectToCustomerDefaultPage,
                    restrictedToProductTypes: [products.MESSAGINGSERVICE]
                },
                {
                    path: '/:customerId/services/onewaymessaging',
                    title: '1-Way Messaging Services',
                    linkable: true,
                    exact: false,
                    component: lazy(() => import('src/views/Services/OneWayMessaging')),
                    restrictedToProductTypes: [products.ONEWAYMESSAGINGSERVICE],
                    routes: [
                        {
                            path: '/:customerId/services/onewaymessaging',
                            title: '1-Way Messaging Services',
                            linkable: true,
                            exact: true,
                            component: lazy(() => import('src/views/Services/OneWayMessaging/OneWayMessaging')),
                            restrictedToProductTypes: [products.ONEWAYMESSAGINGSERVICE]
                        },
                        {
                            path: '/:customerId/services/onewaymessaging/new',
                            title: 'New 1-Way Messaging service',
                            linkable: true,
                            exact: true,
                            component: lazy(() => import('src/views/Services/OneWayMessaging/OneWayService')),
                            restrictedToProductTypes: [products.ONEWAYMESSAGINGSERVICE]
                        },
                        {
                            path: '/:customerId/services/onewaymessaging/:serviceId',
                            TitleComponent: OneWayServiceTitle,
                            linkable: true,
                            exact: false,
                            component: lazy(() => import('src/views/Services/OneWayMessaging/OneWayService')),
                            restrictedToProductTypes: [products.ONEWAYMESSAGINGSERVICE]
                        }
                    ]
                },
                {
                    path: '/:customerId/services/twowaymessaging',
                    title: '2-Way Messaging Services',
                    linkable: true,
                    exact: false,
                    component: lazy(() => import('src/views/Services/TwoWayMessaging')),
                    restrictedToProductTypes: [products.TWOWAYMESSAGINGSERVICE],
                    routes: [
                        {
                            path: '/:customerId/services/twowaymessaging',
                            title: '2-Way Messaging Services',
                            linkable: true,
                            exact: true,
                            component: lazy(() => import('src/views/Services/TwoWayMessaging/TwoWayMessaging')),
                            restrictedToProductTypes: [products.TWOWAYMESSAGINGSERVICE]
                        },
                        {
                            path: '/:customerId/services/twowaymessaging/info/:serviceId',
                            TitleComponent: TwoWayServiceTitle,
                            linkable: true,
                            exact: false,
                            component: lazy(() => import('src/views/Services/TwoWayMessaging/TwoWayServiceInfo')),
                            restrictedToProductTypes: [products.TWOWAYMESSAGINGSERVICE]
                        },
                        {
                            path: '/:customerId/services/twowaymessaging/new',
                            title: 'new 2-Way Messaging service',
                            linkable: true,
                            exact: false,
                            component: lazy(() => import('src/views/Services/TwoWayMessaging/TwoWayServiceNew')),
                            restrictedToProductTypes: [products.TWOWAYMESSAGINGSERVICE]
                        },
                        {
                            path: '/:customerId/services/twowaymessaging/edit/:serviceId',
                            TitleComponent: TwoWayServiceTitle,
                            linkable: false,
                            exact: false,
                            component: lazy(() => import('src/views/Services/TwoWayMessaging/TwoWayServiceEdit')),
                            restrictedToProductTypes: [products.TWOWAYMESSAGINGSERVICE]
                        },
                        // Redirect other nonmatching urls to /:customerId/services/twowaymessaging
                        {
                            path: '/:customerId/services/twowaymessaging/*',
                            component: () => <RedirectWithParams path="/:customerId/services/twowaymessaging" />
                        }
                    ]
                },
                {
                    path: '/:customerId/services/groupmessaging',
                    title: 'Group Messaging',
                    linkable: true,
                    exact: false,
                    component: lazy(() => import('src/views/Services/GroupMessaging')),
                    restrictedToProductTypes: [products.GROUPSERVICE],
                    routes: [
                        {
                            path: '/:customerId/services/groupmessaging',
                            title: 'Group Messaging',
                            linkable: true,
                            exact: true,
                            component: lazy(() => import('src/views/Services/GroupMessaging/GroupMessaging')),
                            restrictedToProductTypes: [products.GROUPSERVICE]
                        },
                        {
                            path: '/:customerId/services/groupmessaging/new/*',
                            linkable: false,
                            exact: false,
                            component: lazy(() => import('src/views/Services/GroupMessaging/GroupServiceNew')),
                            restrictedToProductTypes: [products.GROUPSERVICE]
                        },
                        {
                            path: '/:customerId/services/groupmessaging/edit/:serviceId',
                            TitleComponent: GroupMessagingServiceTitle,
                            linkable: false,
                            exact: false,
                            component: lazy(() => import('src/views/Services/GroupMessaging/GroupServiceEdit')),
                            restrictedToProductTypes: [products.GROUPSERVICE]
                        },
                        {
                            path: '/:customerId/services/groupmessaging/edit/:serviceId/*',
                            linkable: false,
                            exact: false,
                            component: lazy(() => import('src/views/Services/GroupMessaging/GroupServiceEdit')),
                            restrictedToProductTypes: [products.GROUPSERVICE]
                        },
                        {
                            path: '/:customerId/services/groupmessaging/:serviceId/*',
                            TitleComponent: GroupMessagingServiceTitle,
                            linkable: true,
                            exact: false,
                            component: lazy(() => import('src/views/Services/GroupMessaging/GroupServiceInfo')),
                            restrictedToProductTypes: [products.GROUPSERVICE]
                        }
                    ]
                },
                {
                    path: '/:customerId/services/dialog',
                    title: 'Dialog Services',
                    linkable: true,
                    exact: false,
                    component: lazy(() => import('src/views/Services/DialogServices')),
                    restrictedToProductTypes: [products.DIALOGSERVICE],
                    routes: [
                        {
                            // Dialog service grid/listview
                            path: '/:customerId/services/dialog',
                            title: 'Dialog Services',
                            linkable: true,
                            exact: true,
                            component: lazy(() => import('src/views/Services/DialogServices/DialogServices')),
                            restrictedToProductTypes: [products.DIALOGSERVICE]
                        },
                        {
                            // Page with Dialog service tabs
                            path: '/:customerId/services/dialog/:serviceId',
                            TitleComponent: DialogServiceTitle,
                            linkable: true,
                            exact: false,
                            component: lazy(() => import('src/views/Services/DialogServices/DialogService')),
                            restrictedToProductTypes: [products.DIALOGSERVICE]
                        },
                        // Redirect other nonmatching urls to /:customerId/services/dialog
                        {
                            path: '/:customerId/services/dialog/*',
                            component: () => <RedirectWithParams path="/:customerId/services/dialog" />
                        }
                    ]
                },
                {
                    path: '/:customerId/services/codeservice',
                    linkable: true,
                    title: 'Code Services',
                    exact: false,
                    component: lazy(() => import('src/views/Services/Code')),
                    restrictedToProductTypes: [products.CODESERVICE],
                    routes: [
                        {
                            path: '/:customerId/services/codeservice',
                            title: 'Code Service',
                            linkable: true,
                            exact: true,
                            component: lazy(() => import('src/views/Services/Code/CodeServices')),
                            restrictedToProductTypes: [products.CODESERVICE]
                        },
                        {
                            path: '/:customerId/services/codeservice/new',
                            title: 'New Code service',
                            linkable: true,
                            exact: false,
                            component: lazy(() => import('src/views/Services/Code/CodeServiceNew')),
                            restrictedToProductTypes: [products.CODESERVICE]
                        },
                        {
                            path: '/:customerId/services/codeservice/edit/:serviceId',
                            TitleComponent: CodeServiceTitle,
                            linkable: false,
                            exact: false,
                            component: lazy(() => import('src/views/Services/Code/CodeServiceEdit')),
                            restrictedToProductTypes: [products.CODESERVICE]
                        },
                        {
                            path: '/:customerId/services/codeservice/:serviceId/*',
                            TitleComponent: CodeServiceTitle,
                            linkable: true,
                            exact: false,
                            component: lazy(() => import('src/views/Services/Code/CodeServiceInfo')),
                            restrictedToProductTypes: [products.CODESERVICE]
                        }
                    ]
                },
                {
                    path: '/:customerId/services/carrierbilling',
                    linkable: true,
                    title: 'Carrier Billing Services',
                    exact: false,
                    component: lazy(() => import('src/views/Services/CarrierBilling')),
                    restrictedToProductTypes: [products.CARRIERBILLINGSERVICE],
                    routes: [
                        {
                            path: '/:customerId/services/carrierbilling',
                            title: 'Carrier Billing Services',
                            linkable: true,
                            exact: true,
                            component: lazy(() => import('src/views/Services/CarrierBilling/CarrierBillingServices')),
                            restrictedToProductTypes: [products.CARRIERBILLINGSERVICE]
                        },
                        {
                            path: '/:customerId/services/carrierbilling/:serviceId/*',
                            TitleComponent: CarrierBillingServiceTitle,
                            linkable: true,
                            exact: false,
                            component: lazy(() => import('src/views/Services/CarrierBilling/CarrierBillingServiceInfo')),
                            restrictedToProductTypes: [products.CARRIERBILLINGSERVICE]
                        }
                    ]
                },
                {
                    path: '/:customerId/services/surveyservice',
                    linkable: true,
                    title: 'Survey Services',
                    exact: false,
                    component: lazy(() => import('src/views/Services/Survey')),
                    restrictedToProductTypes: [products.SURVEYSERVICE],
                    routes: [
                        {
                            path: '/:customerId/services/surveyservice',
                            title: 'Survey Services',
                            linkable: true,
                            exact: true,
                            component: lazy(() => import('src/views/Services/Survey/SurveyServices')),
                            restrictedToProductTypes: [products.SURVEYSERVICE]
                        },
                        {
                            path: '/:customerId/services/surveyservice/:serviceId/*',
                            TitleComponent: SurveyServiceTitle,
                            linkable: true,
                            exact: false,
                            component: lazy(() => import('src/views/Services/Survey/SurveyServiceInfo')),
                            restrictedToProductTypes: [products.SURVEYSERVICE]
                        }
                    ]
                },
                {
                    path: '/:customerId/management',
                    title: 'Management',
                    linkable: false,
                    exact: false,
                    component: lazy(() => import('src/views/Management')),
                    restrictedToProductTypes: [products.MESSAGINGSERVICE],
                    routes: [
                        {
                            path: '/:customerId/management/messagingaccounts',
                            linkable: true,
                            title: 'Messaging Accounts',
                            exact: true,
                            component: lazy(() => import('src/views/Management/MessagingAccounts')),
                            restrictedToProductTypes: [products.MESSAGINGSERVICE],
                            restrictedToRoles: ['Admin']
                        },
                        {
                            path: '/:customerId/management/messagingaccounts/:accountId',
                            TitleComponent: MessagingAccountTitle,
                            linkable: false,
                            title: 'Messaging Accounts',
                            exact: false,
                            component: lazy(() => import('src/views/Management/MessagingAccounts/MessagingAccountService')),
                            restrictedToProductTypes: [products.MESSAGINGSERVICE],
                            restrictedToRoles: ['Admin']
                        },
                        {
                            path: '/:customerId/management/subscriptionkeys',
                            linkable: true,
                            title: 'Subscription Keys',
                            exact: false,
                            component: lazy(() => import('src/views/Management/ApiSubscriptions')),
                            restrictedToProductTypes: [products.APISUBSCRIPTIONMANAGEMENT],
                            restrictedToRoles: ['Admin']
                        },
                        {
                            path: '/:customerId/management/pat',
                            linkable: true,
                            title: 'Personal Access Tokens',
                            exact: false,
                            component: lazy(() => import('src/views/Management/PersonalAccessTokens')),
                            restrictedToProductTypes: [products.PATMANAGEMENT],
                            restrictedToRoles: ['Admin']
                        },
                        {
                            path: '/:customerId/management/messagingtemplates',
                            linkable: true,
                            title: 'Messaging Templates',
                            exact: false,
                            component: lazy(() => import('src/views/Management/Templates')),
                            restrictedToProductTypes: [products.DIALOGSERVICE]
                        },
                        {
                            path: '/:customerId/management/optouts',
                            linkable: true,
                            title: 'Opt-out numbers',
                            exact: false,
                            component: lazy(() => import('src/views/Management/AdBan')),
                            restrictedToProductTypes: [products.ADBANSERVICE]
                        },
                        {
                            path: '/:customerId/management/optouts',
                            linkable: true,
                            title: 'Opt-out numbers',
                            exact: false,
                            component: lazy(() => import('src/views/Management/AdBan')),
                            restrictedToProductTypes: [products.ADBANSERVICE]
                        },
                        {
                            path: '/:customerId/management/optoutservices',
                            title: 'Opt-out services',
                            linkable: true,
                            exact: false,
                            component: lazy(() => import('src/views/Management/AdBanServices')),
                            restrictedToProductTypes: [products.ADBANSERVICE],
                            restrictedToRoles: ['Admin'],
                            routes: [
                                {
                                    path: '/:customerId/management/optoutservices',
                                    title: 'Opt-out services',
                                    linkable: true,
                                    exact: true,
                                    component: lazy(() => import('src/views/Management/AdBanServices/AdBanServices')),
                                    restrictedToProductTypes: [products.ADBANSERVICE],
                                    restrictedToRoles: ['Admin']
                                },
                                {
                                    path: '/:customerId/management/optoutservices/new',
                                    title: 'New Opt-out service',
                                    linkable: true,
                                    exact: false,
                                    component: lazy(() => import('src/views/Management/AdBanServices/AdBanServiceNew')),
                                    restrictedToProductTypes: [products.ADBANSERVICE],
                                    restrictedToRoles: ['Admin']
                                },
                                {
                                    path: '/:customerId/management/optoutservices/edit/:serviceId',
                                    TitleComponent: AdBanServiceTitle,
                                    linkable: false,
                                    exact: false,
                                    component: lazy(() => import('src/views/Management/AdBanServices/AdBanServiceEdit')),
                                    restrictedToProductTypes: [products.ADBANSERVICE],
                                    restrictedToRoles: ['Admin']
                                },
                                {
                                    path: '/:customerId/management/optoutservices/:serviceId',
                                    TitleComponent: AdBanServiceTitle,
                                    linkable: true,
                                    exact: false,
                                    component: lazy(() => import('src/views/Management/AdBanServices/AdBanServiceInfo')),
                                    restrictedToProductTypes: [products.ADBANSERVICE],
                                    restrictedToRoles: ['Admin']
                                }
                            ]
                        },
                        {
                            path: '/:customerId/management/useraccess',
                            linkable: true,
                            title: 'User Access',
                            exact: false,
                            component: lazy(() => import('src/views/Management/UserAccess')),
                            restrictedToProductTypes: [products.USERACCESS],
                            restrictedToRoles: ['Admin'],
                            routes: [
                                {
                                    path: '/:customerId/management/useraccess/users',
                                    linkable: true,
                                    title: 'Users',
                                    exact: false,
                                    component: lazy(() => import('src/views/Management/UserAccess/AccessGroupTab')),
                                    restrictedToProductTypes: [products.USERACCESS],
                                    restrictedToRoles: ['Admin'],
                                    routes: [
                                        {
                                            path: '/:customerId/management/useraccess/users',
                                            linkable: false,
                                            title: 'Users',
                                            exact: true,
                                            component: lazy(() => import('src/views/Management/UserAccess/UserAccessTabs')),
                                            restrictedToProductTypes: [products.USERACCESS],
                                            restrictedToRoles: ['Admin'],
                                        },
                                        {
                                            path: '/:customerId/management/useraccess/users/:userId',
                                            linkable: false,
                                            title: 'User',
                                            exact: false,
                                            TitleComponent: UserTitle,
                                            component: lazy(() => import('src/views/Management/UserAccess/UserSelected')),
                                            restrictedToProductTypes: [products.USERACCESS],
                                            restrictedToRoles: ['Admin'],
                                        }
                                    ]
                                },
                                {
                                    path: '/:customerId/management/useraccess/accessgroups',
                                    linkable: true,
                                    title: 'Access Groups',
                                    exact: false,
                                    component: lazy(() => import('src/views/Management/UserAccess/AccessGroupTab')),
                                    restrictedToProductTypes: [products.USERACCESS],
                                    restrictedToRoles: ['Admin'],
                                    routes: [
                                        {
                                            path: '/:customerId/management/useraccess/accessgroups',
                                            linkable: false,
                                            title: 'Access Groups',
                                            exact: true,
                                            component: lazy(() => import('src/views/Management/UserAccess/UserAccessTabs')),
                                            restrictedToProductTypes: [products.USERACCESS],
                                            restrictedToRoles: ['Admin'],
                                        },
                                        {
                                            path: '/:customerId/management/useraccess/accessgroups/:accessGroupId',
                                            linkable: false,
                                            title: 'Access Group',
                                            exact: false,
                                            TitleComponent: AccessGroupTitle,
                                            component: lazy(() => import('src/views/Management/UserAccess/AccessGroupSelected')),
                                            restrictedToProductTypes: [products.USERACCESS],
                                            restrictedToRoles: ['Admin'],
                                        }
                                    ]
                                },
                                {
                                    path: '/:customerId/management/useraccess/restrictedresources',
                                    linkable: true,
                                    title: 'Restricted Resources',
                                    exact: false,
                                    component: lazy(() => import('src/views/Management/UserAccess/AccessGroupTab')),
                                    restrictedToProductTypes: [products.USERACCESS],
                                    restrictedToRoles: ['Admin'],
                                    routes: [
                                        {
                                            path: '/:customerId/management/useraccess/restrictedresources',
                                            linkable: false,
                                            title: 'Restricted Resources',
                                            exact: true,
                                            component: lazy(() => import('src/views/Management/UserAccess/UserAccessTabs')),
                                            restrictedToProductTypes: [products.USERACCESS],
                                            restrictedToRoles: ['Admin'],
                                        }
                                    ]
                                },
                                {
                                    exact: true,
                                    path: '/:customerId/management/useraccess',
                                    component: () => <RedirectWithParams path="/:customerId/management/useraccess/users" />
                                }
                            ]
                        },
                        {
                            path: '/:customerId/management/urlshortener',
                            linkable: true,
                            title: 'Url Shortener & Qr',
                            exact: false,
                            component: lazy(() => import('src/views/Management/UrlShortener')),
                            restrictedToProductTypes: [products.URLSHORTENERSERVICE],
                            routes: [
                                {
                                    path: '/:customerId/management/urlshortener',
                                    title: 'Url Shortener & Qr',
                                    linkable: true,
                                    exact: true,
                                    component: lazy(() => import('src/views/Management/UrlShortener/ShortLinks')),
                                    restrictedToProductTypes: [products.URLSHORTENERSERVICE]
                                }
                            ]
                        },
                        {
                            path: '/:customerId/management/waonboarding',
                            linkable: true,
                            title: 'WhatsApp Onboarding',
                            exact: false,
                            component: lazy(() => import('src/views/Management/WhatsApp')),
                            restrictedToProductTypes: [products.DIALOGSERVICE, products.TWOWAYMESSAGINGSERVICE],
                            restrictedToRoles: ['Admin'],
                            routes: [
                                {
                                    path: '/:customerId/management/waonboarding',
                                    title: 'WhatsApp Onboarding',
                                    linkable: true,
                                    exact: true,
                                    component: lazy(() => import('src/views/Management/WhatsApp/WhatsAppInfo')),
                                    restrictedToProductTypes: [products.DIALOGSERVICE, products.TWOWAYMESSAGINGSERVICE],
                                    restrictedToRoles: ['Admin']
                                }
                            ]
                        },
                        {
                            path: '*',
                            component: RedirectToCustomerDefaultPage
                        }
                    ]
                },
                {
                    path: '/:customerId/messagelog',
                    title: 'Message Log',
                    linkable: false,
                    exact: false,
                    component: lazy(() => import('src/views/MessageLog')),
                    restrictedToProductTypes: [products.MESSAGEPERSISTENCESERVICE]
                },
                {
                    path: '/:customerId/scheduledmessages',
                    title: 'Scheduled Messages',
                    linkable: false,
                    exact: false,
                    component: lazy(() => import('src/views/SendMessage/ScheduledMessages')),
                    restrictedToProductTypes: [products.MESSAGEPERSISTENCESERVICE]
                },
                {
                    path: '/:customerId/reports',
                    title: 'Customer Billing Reports',
                    linkable: true,
                    exact: false,
                    component: lazy(() => import('src/views/Reports')),
                    restrictedToProductTypes: [products.REPORTINGSERVICE],
                    restrictedToRoles: ['Admin']
                },
                {
                    path: '/:customerId/alerts',
                    exact: true,
                    component: lazy(() => import('src/views/Alerts'))
                },
                {
                    path: '/:customerId/news',
                    exact: true,
                    component: lazy(() => import('src/views/News'))
                },
                {
                    path: '/:customerId/support',
                    exact: true,
                    component: lazy(() => import('src/views/Contact'))
                },
                {
                    path: '/:customerId/*',
                    component: lazy(() => import('src/views/Error404'))
                },
                {
                    path: '*',
                    component: () => <Redirect to="/errors/error-404" />
                }
            ]
    },
    {
        path: '/errortest',
        exact: true,
        component: ErrorBoundaryTest
    },
    {
        path: '/:customerId',
        exact: true,
        component: RedirectToCustomerDefaultPage
    },
    {
        component: RedirectToDefaultCustomer
    }
];