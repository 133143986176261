import { RESET_REDUCER_STATE } from 'src/actions';
import * as ACTIONS from 'src/actions/twoWayMessagingActions';

const serviceDefaultValues = {
    customerId: '',
    defaultSender: '',
    dlrUrl: '',
    endDate: null,
    messagingAccountId: '',
    name: '',
    rCodeServiceId: '',
    serviceState: 'Active',
    startDate: new Date(),
    serviceConfiguration: {
        dialogService: false,
        allowWebhooks: false
    },
    hiddenContent: false
};

const initialState = {
    services: [],
    customerInputSources: [],
    customerInputSourcesByInputSourceId: {},
    customerInputSourcesLoading: true,
    refreshServices: false,
    twoWayServicesLoading: false,
    newTwoWayService: { ...serviceDefaultValues },
    editedTwoWayService: { ...serviceDefaultValues },
    editedTwoWayServiceInitialHiddenContent: false,
    twoWayServiceLoading: false
};

const twoWayMessagingReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.SET_TWOWAYSERVICES: {
            return {
                ...state,
                refreshServices: false,
                servicesLoading: false,
                services: [...action?.data ?? []]
            };
        }
        case ACTIONS.SET_TWOWAYSERVICE: {
            return {
                ...state,
                services: [
                    ...state.services.filter((s) => s.id !== action?.data?.id),
                    action.data
                ]
            };
        }
        case ACTIONS.SET_TWOWAYSERVICES_LOADING: {
            return {
                ...state,
                servicesLoading: Boolean(action.data)
            };
        }
        case ACTIONS.SET_CUSTOMER_INPUTSOURCES_LOADING: {
            return {
                ...state,
                customerInputSourcesLoading: true
            };
        }
        case ACTIONS.SET_CUSTOMER_INPUTSOURCES: {
            const nonRootInputSources = action.data?.filter?.((inputSourceMapping) => inputSourceMapping?.inputSource?.address !== 'Root') ?? [];
            return {
                ...state,
                customerInputSources: nonRootInputSources.map((inputSourceMapping) => ({
                    type: inputSourceMapping?.inputSource?.type,
                    address: inputSourceMapping?.inputSource?.address,
                    metadata: inputSourceMapping?.inputSource?.metadata ?? []
                })) ?? [],
                customerInputSourcesByInputSourceId: nonRootInputSources.reduce((acc, ism) => {
                    const inputSourceId = ism?.inputSource?.id;

                    if (!inputSourceId) {
                        return acc;
                    }

                    return { ...acc, [inputSourceId]: ism?.inputSource };
                }, {}) ?? {},
                customerInputSourcesLoading: false
            };
        }
        case ACTIONS.REFERSH_TWOWAYSERVICES: {
            return {
                ...state,
                refreshServices: true
            };
        }
        case ACTIONS.TWOWAYSERVICEUPDATED: {
            return {
                ...state,
                services: state.services.reduce((services, service) => {
                    if (service?.id === action?.data?.id) {
                        return [...services, action.data];
                    }
                    return [...services, service];
                }, [])
            };
        }
        case ACTIONS.TWOWAYSERVICEDELETED: {
            return {
                ...state,
                services: state.services.filter((service) => service?.id !== action?.data)
            };
        }
        case ACTIONS.SET_NEW_TWOWAYSERVICE: {
            if (typeof action.data !== 'object') {
                return { ...state };
            }
            return {
                ...state,
                newTwoWayService: {
                    ...state.newTwoWayService,
                    ...action.data,
                    serviceConfiguration: {
                        ...state.newTwoWayService.serviceConfiguration,
                        ...action.data?.serviceConfiguration ? { ...action.data.serviceConfiguration } : {}
                    }
                }
            };
        }
        case ACTIONS.SET_EDITED_TWOWAYSERVICE: {
            if (typeof action.data !== 'object') {
                return { ...state };
            }
            return {
                ...state,
                editedTwoWayService: {
                    ...state.editedTwoWayService,
                    ...action.data,
                    serviceConfiguration: {
                        ...state.editedTwoWayService.serviceConfiguration,
                        ...action.data?.serviceConfiguration ? { ...action.data.serviceConfiguration } : {}
                    }
                }
            };
        }
        case ACTIONS.SET_TWOWAYSERVICE_LOADING: {
            return {
                ...state,
                twoWayServiceLoading: Boolean(action?.data)
            };
        }
        case ACTIONS.SET_EDITEDTWOWAYSERVICE_INITIAL_HIDDENCONTENT: {
            return {
                ...state,
                editedTwoWayServiceInitialHiddenContent: Boolean(action?.data)
            };
        }
        case ACTIONS.CLEAR_NEW_TWOWAY_SERVICE: {
            return {
                ...state,
                newTwoWayService: { ...serviceDefaultValues }
            };
        }
        case RESET_REDUCER_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default twoWayMessagingReducer;